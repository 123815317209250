import React, { useRef } from "react";
import { useEffect } from "react";
import useStore from "../../../zustand/store";
function SlippageComp({ slippage, handleSlippage }) {
  const configuration = useStore((state) => state.configuration);
  return (
    <div className="bw-flex bw-items-center bw-justify-between">
      <p className="bw-text-lg bw-font-normal bw-text-text-primary">
        Max Slippage
      </p>
      <div
        style={{
          boxShadow:
            (configuration &&
              configuration.customTheme &&
              configuration.customTheme?.shadow?.boxShadow) ||
            "0px 0px 12.5px 0px #0000001A",
          border: "1px solid var(--tw-border-primary)",
        }}
        className=" bw-text-lg  bw-w-[131px] bw-h-[40px] bw-rounded-md bw-flex bw-items-center "
      >
        <input
          onKeyUp={(e) => {
            if (e.key === "Enter") {
              e.target.blur();
            }
          }}
          onFocus={(e) => {
            e.target.parentElement.style.border =
              "1px solid var(--tw-text-primary)";
            e.target.parentElement.style.fontWeight = 600;
          }}
          onBlur={(e) => {
            e.target.parentElement.style.border =
              "1px solid var(--tw-border-primary)";
            e.target.parentElement.style.fontWeight = 400;
          }}
          value={slippage}
          onChange={handleSlippage}
          type="number"
          className="bw-bg-transparent  bw-text-text-primary bw-pl-2 bw-text-right bw-w-[80%]"
        />
        <div className=" bw-flex bw-items-center bw-text-text-secondary bw-justify-center bw-w-[20%]">
          %
        </div>
      </div>
    </div>
  );
}

export default SlippageComp;
