import Modal from "@mui/material/Modal";
import useStore from "../../../zustand/store";
import SolanaFeeComp from "./SolanaFeeComp";
import EVMFeeComp from "./EVMFeeComp";
function Settings({ open, handleClose }) {
  const fromChain = useStore((state) => state.fromChain);

  const style = {
    // position: "absolute",
    // top: "40%",
    // left: "47%",
    // transform: "translate(-50%, -50%)",

    bgcolor: "var(--tw-bg-container)",
  };
  return (
    <Modal
      open={open}
      disablePortal={true}
      onClose={handleClose}
      disableEnforceFocus
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      className="bw-w-full md:bw-w-[443px] bw-h-max bw-absolute"
    >
      <div style={style}>
        {fromChain.networkType == "sol" ? (
          <SolanaFeeComp handleClose={handleClose} />
        ) : (
          <div>
            <EVMFeeComp handleClose={handleClose} />
          </div>
        )}
      </div>
    </Modal>
  );
}

export default Settings;
