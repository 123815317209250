import React, { useState } from "react";
import styles from "./Settings.module.css";
import images from "../../../images";
import Degen from "../../Svg/degen";
import useStore from "../../../zustand/store";
function OptionsComp({ options, handleOptions }) {
  const optionsArray = [
    { name: "Medium", value: "medium" },
    { name: "High", value: "high" },
    { name: "Degen", value: "ultra" },
  ];
  const { dgen } = images;
  const configuration = useStore((state) => state.configuration);
  return (
    <div className="bw-mt-5 bw-flex bw-items-center bw-justify-between">
      <h2 className="bw-text-sm bw-font-normal bw-text-text-secondary">
        Speed
      </h2>
      <div
        style={{
          boxShadow:
            (configuration &&
              configuration.customTheme &&
              configuration.customTheme?.shadow?.boxShadow) ||
            "0px 0px 12.5px 0px #0000001A",
        }}
        className="bw-flex bw-border bw-bg-background-card bw-w-[70%] md:bw-w-[60%] bw-h-[40px] bw-rounded-md bw-border-border-primary  bw-items-center bw-justify-between"
      >
        {optionsArray.map((item, index, arr) => {
          return (
            <button
              onClick={() => {
                options.value == item.value
                  ? handleOptions(
                      {
                        name: "",
                        value: "",
                      },
                      false
                    )
                  : handleOptions(item, true);
              }}
              className={`
                 bw-flex bw-flex-1   bw-relative bw-h-full  bw-rounded-md  bw-gap-x-1 bw-justify-center bw-items-center `}
            >
              {item.value === "ultra" ? (
                <Degen
                  fill={
                    item.value === options.value
                      ? "var(--tw-text-primary)"
                      : "var(--tw-text-secondary)"
                  }
                />
              ) : (
                <></>
              )}
              <p
                // style={{
                //   transition: "font-weight 0.01s ease-in-out",
                // }}
                className={` bw-text-center  ${
                  item.value === options.value
                    ? "bw-font-bold bw-text-xs bw-text-text-primary"
                    : "bw-text-xs font-normal bw-text-text-secondary "
                }`}
              >
                {item.name}
              </p>
              {arr.length - 1 !== index && (
                <div className="bw-h-[70%] bw-absolute bw-right-0 bw-w-[1px] bw-border bw-border-border-primary"></div>
              )}

              <div
                style={{
                  transition: "opacity 0.5s ease-in-out",
                  ...configuration?.gradientStyle,
                }}
                className={`bw-w-full
              
                 ${
                   item.value === options.value
                     ? "bw-opacity-100"
                     : "bw-opacity-0"
                 } bw-absolute bw-bottom-0 bw-h-[2px] bw-rounded-md ${
                  !configuration?.gradientStyle && styles.gradient
                }`}
              ></div>
            </button>
          );
        })}
      </div>
    </div>
  );
}

export default OptionsComp;
