import React from "react";
import { Button } from "@mui/material";
function Header({ handleClose }) {
  return (
    <div className="bw-w-full bw-relative  bw-flex bw-items-center bw-justify-center">
      <h2 className="bw-text-2xl bw-font-bold bw-text-text-primary">Config</h2>
      <Button
        disableRipple
        onClick={handleClose}
        className="bw-p-0 bw-absolute bw-right-1 bw-min-w-max bw-min-h-max"
      >
        <div className="bw-text-lg be-font-bold  bw-flex bw-justify-center  bw-items-center bw-text-text-primary">
          x
        </div>
      </Button>
    </div>
  );
}

export default Header;
