import { create } from "zustand";
import { persist } from "zustand/middleware";
import { devtools } from "zustand/middleware";
import deepmerge from "deepmerge";
const useStore = create(
  devtools(
    persist(
      (set) => ({
        walletData: {},
        setWalletData: (payload) => set((state) => ({ walletData: payload })),
        timerValue: { timer: 0 },
        setTimerValue: (payload) => set((state) => ({ timerValue: payload })),
        gradient: null,
        setGradient: (payload) => set((state) => ({ gradient: payload })),
        configuration: {},
        setConfiguration: (payload) =>
          set((state) => ({ configuration: payload })),
        persist: {},
        setPersist: (payload) => set((state) => ({ persist: payload })),
        route: "",
        setRoute: (payload) => set((state) => ({ route: payload })),
        fromChain: { chain: "" },
        setFromChain: (payload) => set((state) => ({ fromChain: payload })),
        toChain: { chain: "" },
        setToChain: (payload) => set((state) => ({ toChain: payload })),
        fromCoin: { coin: "" },
        setFromCoin: (payload) => set((state) => ({ fromCoin: payload })),
        toCoin: { coin: "" },
        setToCoin: (payload) => set((state) => ({ toCoin: payload })),
        theme: "light",
        setTheme: (payload) => set((state) => ({ theme: payload })),
        amount: "",
        setAmount: (payload) => set((state) => ({ amount: payload })),
        txnDetails: {},
        setTxnDetails: (payload) => set((state) => ({ txnDetails: payload })),
        customWallet: "",
        setCustomWallet: (payload) =>
          set((state) => ({ customWallet: payload })),
        isCustomWalletSet: false,
        setIsCustomWalletSet: (payload) =>
          set((state) => ({ isCustomWalletSet: payload })),
        toDisconnect: false,
        setToDisconnect: (payload) =>
          set((state) => ({ toDisconnect: payload })),
        userId: null,
        setUserId: (payload) => set((state) => ({ userId: payload })),
        integratorId: null,
        setIntegratorId: (payload) =>
          set((state) => ({ integratorId: payload })),
        cosmosChainIds: [],
        setCosmosChainIds: (payload) =>
          set((state) => ({ cosmosChainIds: payload })),
        broadCastMode: "Priority Fee",
        setBroadCastMode: (payload) =>
          set((state) => ({ broadCastMode: payload })),
        customFee: null,
        setCustomFee: (payload) => set((state) => ({ customFee: payload })),
        evmSpeed: { name: "", value: "" },
        setEvmSpeed: (payload) => set((state) => ({ evmSpeed: payload })),
        evmGas: null,
        setEvmGas: (payload) => set((state) => ({ evmGas: payload })),
        tokens: {},
        setTokens: (payload) => set((state) => ({ tokens: payload })),
        searchCustomCoin: { chainId: "", address: "" },
        setSearchCustomCoin: (payload) =>
          set((state) => ({ searchCustomCoin: payload })),
        slippage: "0.5",
        setSlippage: (payload) => set((state) => ({ slippage: payload })),
        option: { name: "", value: "" },
        setOption: (payload) => set((state) => ({ option: payload })),
      }),

      {
        name: "blockend_store",
        merge: (persistedState, currentState) =>
          deepmerge(currentState, persistedState),
        partialize: (state) =>
          Object.fromEntries(
            Object.entries(state).filter(
              ([key]) =>
                ![
                  "timerValue",
                  "walletData",
                  "gradient",
                  "configuration",
                  "fromChain",
                  "toChain",
                  "fromCoin",
                  "toCoin",
                  "amount",
                  "route",
                  "txnDetails",
                  "toDisconnect",
                  "customWallet",
                  "isCustomWalletSet",
                  "searchCustomCoin",
                ].includes(key)
            )
          ),
      }
    )
  )
);
export default useStore;
