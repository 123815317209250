import React from "react";

function completed({ fill }) {
  return (
    <svg
      width="61"
      height="61"
      viewBox="0 0 61 61"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M56.0464 31.1653V30.2683C56.0464 16.3363 44.5809 5.04223 30.4375 5.04223C16.2941 5.04223 4.82858 16.3363 4.82858 30.2683V31.1653C4.82858 45.0973 16.2941 56.3914 30.4375 56.3914C44.5809 56.3914 56.0464 45.0973 56.0464 31.1653ZM30.4375 0.716797C13.869 0.716797 0.4375 13.9475 0.4375 30.2683V31.1653C0.4375 47.4861 13.869 60.7168 30.4375 60.7168C47.006 60.7168 60.4375 47.4861 60.4375 31.1653V30.2683C60.4375 13.9475 47.006 0.716797 30.4375 0.716797Z"
        fill={fill}
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M43.9322 19.4822L25.6229 44.2042L14.5703 33.7631L18.3344 29.6567L24.8944 35.8537L39.5157 16.1113L43.9322 19.4822Z"
        fill={fill}
      />
    </svg>
  );
}

export default completed;
