import React from "react";
import useStore from "../../../zustand/store";
import styles from "./Settings.module.css";
function SaveButton({ handleSave, inputVal, options }) {
  const configuration = useStore((state) => state.configuration);
  return (
    <div
      style={{ ...configuration?.gradientStyle }}
      className={`${
        !inputVal?.length && !options?.value.length
          ? "bw-bg-border-primary"
          : !configuration?.gradientStyle && styles.gradient
      } bw-rounded-sm bw-p-[1px] bw-mt-10`}
    >
      <button
        className={`bw-w-full bw-bg-background-container  bw-rounded-sm bw-h-[52px]   bw-items-center bw-text-2xl bw-font-normal bw-text-text-secondary bw-flex bw-justify-center`}
        onClick={handleSave}
      >
        Save
      </button>
    </div>
  );
}

export default SaveButton;
