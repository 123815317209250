function TransactionFeeHeading() {
  return (
    <div className="bw-flex bw-gap-x-5 bw-items-center ">
      <h2 className="bw-text-lg bw-font-normal bw-text-text-primary">
        Transaction Fee
      </h2>
      <div className="bw-h-[1px] bw-flex-1 bw-bg-border-primary  "></div>
    </div>
  );
}
export default TransactionFeeHeading;
