import React, { useState, useEffect } from "react";
import DatePickerComp from "./DatePickerComp";
function DateComp({ handleSubmit, value, handleValue }) {
  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);
  function handleFromVal(e) {
    let epoch = new Date(e).getTime();
    setFromDate(epoch);
  }
  function handleToVal(e) {
    const now = new Date();
    e.setHours(now.getHours());
    e.setMinutes(now.getMinutes());
    e.setSeconds(now.getSeconds());
    let epoch = new Date(e).getTime();
    setToDate(epoch);
  }

  function reset() {
    setFromDate(null);
    setToDate(null);
    handleSubmit(null, null);
    if (value == "date") {
      handleValue("all");
    }
  }
  useEffect(() => {
    if (fromDate && toDate) {
      handleSubmit(fromDate, toDate);
    }
  }, [fromDate, toDate]);
  useEffect(() => {
    if (value !== "date" && (fromDate || toDate)) {
      setFromDate(null);
      setToDate(null);
    }
  }, [value]);
  return (
    <div className="bw-flex bw-w-full bw-justify-center bw-items-center bw-gap-x-4">
      <DatePickerComp
        dateVal={fromDate}
        handleDateVal={handleFromVal}
        placeholder={"Select From"}
      />
      <DatePickerComp
        dateVal={toDate}
        handleDateVal={handleToVal}
        placeholder={"Select To"}
      />
      {fromDate || toDate ? (
        <button
          onClick={reset}
          className=" bw-text-text-secondary bw-rounded-md bw-text-xs bw-font-bold"
        >
          Clear
        </button>
      ) : (
        <></>
      )}
    </div>
  );
}

export default DateComp;
