import React, { useState } from "react";
import EmptyComp from "./EmptyComp";
import Wallet from "../Svg/wallet";
import SelectWallet from "../SelectWallet";
function ConnectWalletComp() {
  const [showWallet, setShowWallet] = useState(false);
  function handleShowWallet() {
    setShowWallet((prev) => !prev);
  }

  return (
    <>
      <EmptyComp
        heading="Curious about your crypto moves?"
        description="Connect your wallet to explore your transaction history and rewards"
        buttonProps={{
          callback: () => {
            handleShowWallet();
          },
          buttonText: "Connect Wallet",
        }}
      >
        <Wallet fill={"var(--tw-border-primary)"} />
      </EmptyComp>
      {showWallet && (
        <div className="bw-absolute bw-z-20 bw-p-3 bw-rounded-[15px]  bw-right-0  bw-bg-background-container bw-h-full bw-top-0 bw-w-full">
          <SelectWallet
            handleShowWallet={handleShowWallet}
            portfolio={true}
            classNames={"bw-h-[500px]"}
          />
        </div>
      )}
    </>
  );
}

export default ConnectWalletComp;
