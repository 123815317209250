import useStore from "../../../zustand/store";
import styles from "./Settings.module.css";
function BroadCastMode({ mode, handleMode }) {
  const configuration = useStore((state) => state.configuration);
  return (
    <div className="bw-flex  bw-justify-center">
      <div
        style={{
          boxShadow:
            (configuration &&
              configuration.customTheme &&
              configuration.customTheme?.shadow?.boxShadow) ||
            "0px 0px 12.5px 0px #0000001A",
        }}
        className="bw-flex  bw-relative bw-h-[40px] bw-w-full md:bw-w-[90%] bw-items-center gap-x-1"
      >
        <button
          onClick={() => {
            handleMode("Priority Fee");
          }}
          className={` bw-font-normal bw-text-text-secondary bw-border bw-border-r-0 bw-bg-background-card bw-flex bw-justify-center bw-items-center bw-relative bw-text-sm bw-flex-1 bw-h-[40px] bw-rounded-l-md bw-border-border-primary`}
        >
          <p
            className={`${
              mode === "Priority Fee" ? "bw-opacity-0" : "bw-opacity-100"
            }`}
            style={{ transition: "opacity 0.1s ease" }}
          >
            {" "}
            Priority Fee
          </p>

          <div className="bw-h-[70%] bw-right-0 bw-absolute  bw-w-[1px] bw-bg-border-primary"></div>
        </button>
        <div
          style={{
            transition: "left 0.5s",
            width: "calc(100% / 3)",
            ...configuration?.gradientStyle,
          }}
          className={`${!configuration?.gradientStyle && styles.gradient} ${
            mode === "Both"
              ? "bw-left-[66.5%]"
              : mode === "Priority Fee"
              ? "bw-left-0"
              : "bw-left-[33%]"
          }  bw-z-10   bw-absolute bw-h-[40px] bw-p-[2px] bw-rounded-md `}
        >
          <div className="bw-w-full bw-h-full bw-text-text-primary bw-text-sm bw-font-extrabold bw-flex bw-justify-center bw-items-center bw-rounded-md bw-bg-background-container">
            {mode}
          </div>
        </div>
        <button
          onClick={() => {
            handleMode("Jito Tip");
          }}
          className={`bw-font-normal bw-text-text-secondary bw-border bw-bg-background-card bw-border-x-0 bw-flex bw-justify-center bw-items-center bw-relative bw-text-sm bw-flex-1 bw-border-border-primary bw-h-[40px] `}
        >
          <p
            className={`${
              mode === "Jito Tip" ? "bw-opacity-0" : "bw-opacity-100"
            }`}
            style={{ transition: "opacity 0.1s ease" }}
          >
            Jito Tip
          </p>
          <div className="bw-h-[70%] bw-right-0 bw-absolute  bw-w-[1px] bw-bg-border-primary"></div>
        </button>
        <button
          onClick={() => {
            handleMode("Both");
          }}
          className={`bw-font-normal bw-text-text-secondary bw-border bw-bg-background-card bw-border-l-0 bw-text-sm bw-flex-1 bw-border-border-primary bw-h-[40px] bw-rounded-r-md `}
        >
          <p
            className={`${mode === "Both" ? "bw-opacity-0" : "bw-opacity-100"}`}
            style={{ transition: "opacity 0.1s ease" }}
          >
            {" "}
            Both
          </p>
        </button>
      </div>
    </div>
  );
}

export default BroadCastMode;
