import React from "react";

function daterange({ fill }) {
  return (
    <svg
      width="68"
      height="60"
      viewBox="0 0 68 60"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g opacity="0.6">
        <path
          d="M40.343 5.08967L64.9845 47.7701C67.7403 52.5433 64.2956 58.5098 58.7839 58.5098H9.50083C3.98922 58.5098 0.544469 52.5433 3.30028 47.7701L27.9418 5.08966C30.6976 0.316464 37.5871 0.316469 40.343 5.08967Z"
          fill="white"
          stroke={fill}
          stroke-width="3"
        />
        <path
          d="M36.8159 16.0586V28.7959C36.8159 30.1468 36.7491 31.4755 36.6155 32.7819C36.4819 34.0734 36.3111 35.4466 36.1033 36.9015H32.3623C32.1544 35.4466 31.9837 34.0734 31.8501 32.7819C31.7165 31.4755 31.6497 30.1468 31.6497 28.7959V16.0586H36.8159ZM30.7812 45.2297C30.7812 44.7695 30.8629 44.3316 31.0262 43.9159C31.2043 43.5002 31.4419 43.144 31.7388 42.847C32.0505 42.5501 32.4142 42.3126 32.8299 42.1345C33.2456 41.9563 33.6909 41.8673 34.166 41.8673C34.6262 41.8673 35.0641 41.9563 35.4798 42.1345C35.8955 42.3126 36.2518 42.5501 36.5487 42.847C36.8604 43.144 37.1054 43.5002 37.2835 43.9159C37.4617 44.3316 37.5507 44.7695 37.5507 45.2297C37.5507 45.7048 37.4617 46.1501 37.2835 46.5658C37.1054 46.9666 36.8604 47.3155 36.5487 47.6124C36.2518 47.9093 35.8955 48.1394 35.4798 48.3027C35.0641 48.4809 34.6262 48.5699 34.166 48.5699C33.6909 48.5699 33.2456 48.4809 32.8299 48.3027C32.4142 48.1394 32.0505 47.9093 31.7388 47.6124C31.4419 47.3155 31.2043 46.9666 31.0262 46.5658C30.8629 46.1501 30.7812 45.7048 30.7812 45.2297Z"
          fill={fill}
        />
      </g>
    </svg>
  );
}

export default daterange;
