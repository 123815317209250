import { useEffect, useState } from "react";
import ShowMoreNetworks from "./ShowMoreNetworks";
import styles from "./Selectchain.module.css";
import images from "../../images";
import Skeleton from "@mui/material/Skeleton";
import useStore from "../../zustand/store";
import Search from "../Svg/search";
import CloseButton from "../Svg/closeButton";
import Token from "./tokens";
import { keyBy } from "lodash";
export default function SelectChain({
  setChainData,
  setCoinData,
  handleReset,
  chainData,
  coinData,
  showExchangeList,
  fetchChains,
}) {
  const [showMoreNetwork, setShowMoreNetwork] = useState(false);
  const [value, setValue] = useState("");

  const [topChains, setTopChains] = useState([]);
  const [numberOfChains, setNumberOfChains] = useState(0);
  const [selectChainState, setSelectChainState] = useState({ chain: "" });
  const gradient = useStore((state) => state.gradient);
  const configuration = useStore((state) => state.configuration);

  useEffect(() => {
    if (fetchChains.isSuccess) {
      let tempArray = [...fetchChains?.data?.data?.slice(0, 9)];
      let tempArrayObj = keyBy(tempArray, "chainId");
      let isChainPresent = tempArrayObj[selectChainState.chainId]
        ? true
        : false;
      if (!isChainPresent) {
        tempArray.pop();
        tempArray.push(selectChainState);
        setTopChains(tempArray);
      } else {
        setTopChains(tempArray);
      }
    }
  }, [fetchChains?.data?.data, chainData, selectChainState]);

  useEffect(() => {
    setSelectChainState(chainData);
  }, [chainData]);
  useEffect(() => {
    if (fetchChains.data?.data?.length) {
      let sortCosmos = fetchChains.data?.data.filter((item) => {
        return true;
      });
      setNumberOfChains(sortCosmos.length);
    }
  }, [fetchChains.data?.data]);
  function handleClosePopup(chainData, coinData) {
    if (chainData.chain?.length && coinData?.coin?.length) {
      handleReset();
    }
  }
  function handleBack() {
    setShowMoreNetwork(false);
  }
  function handleSetChainData(data) {
    setSelectChainState(data);
  }

  const { tick } = images;

  return showMoreNetwork ? (
    <ShowMoreNetworks
      handleSetChainData={handleSetChainData}
      data={fetchChains.data?.data}
      handleBack={handleBack}
      network={selectChainState}
    />
  ) : (
    <>
      <div>
        <div className="bw-flex bw-relative bw-justify-center bw-mb-2">
          <button
            onClick={() => {
              handleReset();
              if (!coinData.coin.length || !chainData.chain.length) {
                setCoinData({ coin: "" });
                setChainData({ chain: "" });
              }
            }}
            className="bw-absolute bw-left-0 bw-top-[25%]"
          >
            <CloseButton fill={"var(--tw-text-secondary)"} />
          </button>
          <div className="bw-text-base bw-font-normal bw-text-text-secondary">
            {showExchangeList.charAt(0).toUpperCase() +
              showExchangeList.slice(1)}{" "}
            Network
          </div>
        </div>
        {fetchChains.isLoading || fetchChains.isFetching ? (
          <Skeleton
            animation="wave"
            variant="rectangular"
            width={"100%"}
            height={134}
            className="bw-rounded-md bw-flex bw-justify-center bw-bg-transparent bw-opacity-0  bw-items-center bw-text-text-primary"
          ></Skeleton>
        ) : (
          <div className="bw-flex bw-flex-wrap  bw-gap-x-[3%] bw-justify-start bw-gap-y-5 bw-overflow-y-auto">
            {fetchChains.isSuccess &&
              topChains
                // ?.filter((item) => item.networkType !== "cosmos")
                .map((item, i) => {
                  return (
                    <div
                      key={i}
                      style={
                        selectChainState.name == item.name
                          ? {
                              ...configuration.gradientStyle,
                            }
                          : {}
                      }
                      className={` bw-relative ${
                        selectChainState.name == item.name
                          ? (gradient ? gradient : styles.gradientbackground) +
                            " " +
                            styles.overlay
                          : "bw-bg-transparent"
                      } bw-p-[2px] bw-rounded-[7px]`}
                    >
                      <div
                        onClick={() => {
                          let newObj = {
                            chain: item.name,
                            ...item,
                            // networkType: "cosmos",
                          };
                          handleSetChainData(newObj);
                        }}
                        className="bw-p-2 bw-w-[70px] bw-h-[53px] bw-relative bw-z-10 bw-bg-background-networkcard  bw-rounded-md bw-flex bw-flex-col bw-justify-center bw-items-center"
                        style={{ cursor: "pointer" }}
                        key={i}
                      >
                        <img
                          src={item.image}
                          width={25}
                          className="bw-rounded-[50%]"
                          height={25}
                          alt="img"
                        />{" "}
                        <p
                          className={`bw-text-xs bw-text-center  ${
                            selectChainState.name == item.name
                              ? "bw-text-text-primary bw-font-semibold"
                              : "bw-text-text-primary bw-font-normal"
                          }`}
                        >
                          {item.name?.length > 7
                            ? item.name.substring(0, 7) + ".."
                            : item.name}
                        </p>
                        {selectChainState?.chain == item ? (
                          <img width={10} height={10} src={tick} />
                        ) : (
                          <></>
                        )}
                      </div>
                    </div>
                  );
                })}
            {fetchChains.isSuccess && topChains.length ? (
              <div className="bw-p-[2px]">
                <div
                  onClick={() => {
                    setShowMoreNetwork(true);
                  }}
                  className="bw-p-2 bw-w-[70px] bw-h-[53px] bw-bg-background-networkcard  bw-rounded-md bw-flex bw-flex-col bw-justify-center bw-items-center"
                  style={{ cursor: "pointer" }}
                >
                  <p className="bw-text-xl bw-font-medium bw-text-text-primary">
                    {fetchChains.data?.data?.length
                      ? `+${numberOfChains - 9}`
                      : "0"}
                  </p>
                  <p className="bw-text-xs bw-font-normal bw-text-text-primary">
                    Networks
                  </p>
                </div>
              </div>
            ) : (
              <></>
            )}
          </div>
        )}
      </div>
      <div>
        <div
          className="bw-relative bw-mt-4"
          style={
            value.length
              ? {
                  background: "linear-gradient(to right,#A45EFF   ,#2CFFE4)",
                  padding: "1px",
                  borderRadius: "5px",
                }
              : {}
          }
        >
          <input
            type={value}
            onChange={(e) => {
              setValue(e.target.value);
            }}
            className={`bw-text-sm bw-px-2 bw-w-full bw-h-[34px] ${
              !value.length
                ? "bw-border bw-border-border-primary"
                : "bw-border-0 bw-border-border-transparent"
            } bw-rounded-[5px] bw-font-normal bw-bg-background-container placeholder:bw-text-text-placeholder bw-text-text-primary`}
            placeholder="Search Token Name or Contract Address"
          />

          <div className="bw-absolute bw-right-2 bw-bg-background-container bw-top-[6px]">
            <Search fill={"var(--tw-background-secondary)"} />
          </div>
        </div>
        <Token
          value={value}
          handleClosePopup={handleClosePopup}
          showExchangeList={showExchangeList}
          setChainData={setChainData}
          setCoinData={setCoinData}
          selectChainState={selectChainState}
        />
      </div>
    </>
  );
}
