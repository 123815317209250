import React from "react";

function Wallet({ fill }) {
  return (
    <svg
      width="61"
      height="61"
      viewBox="0 0 61 61"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M56.1089 31.1653V30.2683C56.1089 16.3363 44.6434 5.04223 30.5 5.04223C16.3566 5.04223 4.89108 16.3363 4.89108 30.2683V31.1653C4.89108 45.0973 16.3566 56.3914 30.5 56.3914C44.6434 56.3914 56.1089 45.0973 56.1089 31.1653ZM30.5 0.716797C13.9315 0.716797 0.5 13.9475 0.5 30.2683V31.1653C0.5 47.4861 13.9315 60.7168 30.5 60.7168C47.0685 60.7168 60.5 47.4861 60.5 31.1653V30.2683C60.5 13.9475 47.0685 0.716797 30.5 0.716797Z"
        fill={fill}
      />
      <rect
        x="15.9297"
        y="20.2051"
        width="30.1406"
        height="21.0232"
        rx="4.5"
        stroke={fill}
        stroke-width="3"
      />
      <rect
        x="35.9688"
        y="27.4688"
        width="10.1016"
        height="6.49715"
        rx="3.24858"
        stroke={fill}
        stroke-width="3"
      />
    </svg>
  );
}

export default Wallet;
