import React from "react";

function Date() {
  return (
    <svg
      width="14"
      height="14"
      viewBox="0 0 69 68"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M58.4404 6.92406H51.6404V3.5509C51.6404 2.65628 51.2822 1.7983 50.6446 1.16571C50.007 0.53312 49.1422 0.177734 48.2405 0.177734C47.3387 0.177734 46.4739 0.53312 45.8363 1.16571C45.1987 1.7983 44.8405 2.65628 44.8405 3.5509V6.92406H24.4405V3.5509C24.4405 2.65628 24.0823 1.7983 23.4447 1.16571C22.8071 0.53312 21.9423 0.177734 21.0406 0.177734C20.1388 0.177734 19.274 0.53312 18.6364 1.16571C17.9988 1.7983 17.6406 2.65628 17.6406 3.5509V6.92406H10.8406C8.13539 6.92406 5.54099 7.99021 3.62813 9.88798C1.71526 11.7858 0.640625 14.3597 0.640625 17.0435V57.5215C0.640625 60.2053 1.71526 62.7793 3.62813 64.677C5.54099 66.5748 8.13539 67.641 10.8406 67.641H58.4404C61.1456 67.641 63.74 66.5748 65.6529 64.677C67.5657 62.7793 68.6404 60.2053 68.6404 57.5215V17.0435C68.6404 14.3597 67.5657 11.7858 65.6529 9.88798C63.74 7.99021 61.1456 6.92406 58.4404 6.92406V6.92406ZM61.8404 57.5215C61.8404 58.4161 61.4822 59.2741 60.8446 59.9067C60.207 60.5392 59.3421 60.8946 58.4404 60.8946H10.8406C9.93886 60.8946 9.07406 60.5392 8.43643 59.9067C7.79881 59.2741 7.4406 58.4161 7.4406 57.5215V33.9093H61.8404V57.5215ZM61.8404 27.163H7.4406V17.0435C7.4406 16.1489 7.79881 15.2909 8.43643 14.6584C9.07406 14.0258 9.93886 13.6704 10.8406 13.6704H17.6406V17.0435C17.6406 17.9382 17.9988 18.7961 18.6364 19.4287C19.274 20.0613 20.1388 20.4167 21.0406 20.4167C21.9423 20.4167 22.8071 20.0613 23.4447 19.4287C24.0823 18.7961 24.4405 17.9382 24.4405 17.0435V13.6704H44.8405V17.0435C44.8405 17.9382 45.1987 18.7961 45.8363 19.4287C46.4739 20.0613 47.3387 20.4167 48.2405 20.4167C49.1422 20.4167 50.007 20.0613 50.6446 19.4287C51.2822 18.7961 51.6404 17.9382 51.6404 17.0435V13.6704H58.4404C59.3421 13.6704 60.207 14.0258 60.8446 14.6584C61.4822 15.2909 61.8404 16.1489 61.8404 17.0435V27.163Z"
        fill="#8A8F8E"
      />
    </svg>
  );
}

export default Date;
