import React from "react";
import useStore from "../../zustand/store";
import styles from "./TxnHistoryComp.module.css";

function ButtonComp({ callback, buttonText }) {
  const configuration = useStore((state) => state.configuration);
  return (
    <button
      onClick={callback}
      style={{
        ...configuration?.gradientStyle,
        boxShadow:
          (configuration &&
            configuration.customTheme &&
            configuration.customTheme?.shadow?.boxShadow) ||
          "1px 1px 7px rgba(0, 0, 0, 0.15)",
      }}
      className={`${
        !configuration?.gradientStyle && styles.gradientbutton
      } bw-w-full bw-h-[50px] bw-text-2xl bw-p-[3px] bw-mt-6 bw-border-0 bw-relative bw-z-0  disabled:bw-opacity-60  bw-rounded-sm  bw-font-bold bw-text-text-primary`}
    >
      <div className="bw-w-full bw-flex bw-justify-center bw-items-center bw-rounded-sm bw-h-full bw-bg-background-container">
        <p className="bw-relative bw-z-20 ">{buttonText}</p>
      </div>
    </button>
  );
}
function EmptyComp({ heading, description, children, buttonProps }) {
  return (
    <div className="bw-w-full bw-h-full bw-flex bw-flex-col bw-items-center bw-justify-center">
      {children}
      <h2 className="bw-text-text-primary bw-mt-4 bw-mb-2 bw-text-lg bw-font-semibold">
        {heading}
      </h2>
      <p className="bw-text-text-secondary bw-px-8 bw-text-center bw-text-sm bw-font-normal">
        {description}
      </p>
      {buttonProps ? (
        <ButtonComp
          callback={buttonProps.callback}
          buttonText={buttonProps.buttonText}
        />
      ) : (
        <></>
      )}
    </div>
  );
}

export default EmptyComp;
