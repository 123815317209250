import React, { useContext } from "react";
import { TxnErrorContext } from "../Context/txnErrorContext";
import useStore from "../../zustand/store";
import { Skeleton } from "@mui/material";
function Steps({ item, i }) {
  const { isErrorMessage, handleErrorMessage } = useContext(TxnErrorContext);
  const persist = useStore((state) => state.persist);
  const allSteps = persist?.allSteps;

  return (
    <div>
      <h1
        className={` ${
          item?.texts?.heading?.length > 34 ? "bw-text-base" : "bw-text-lg"
        } ${
          persist?.allSteps?.currentStep == i
            ? "bw-text-text-primary"
            : "bw-text-text-secondary"
        } bw-leading-none bw-mt-[-2px]`}
      >
        {item?.texts?.heading}
      </h1>
      {persist?.stepStatus && persist?.stepStatus[i] ? (
        <>
          <p className="bw-text-sm bw-text-text-secondary bw-ml-3 bw-mt-1">
            {isErrorMessage &&
            persist?.stepStatus[i]?.status !== "failed" &&
            i == persist?.allSteps?.currentStep
              ? "Failed"
              : item?.texts?.status?.[persist?.stepStatus[i]?.status] ||
                item?.texts?.[persist?.stepStatus[i]?.type]}
          </p>
        </>
      ) : i == 0 ? (
        <div className="bw-h-[20px] bw-mt-[4px]  bw-w-full">
          <Skeleton
            className="bw-bg-background-loaderbar bw-rounded-lg bw-w-full"
            animation="wave"
          />
        </div>
      ) : (
        <></>
      )}
    </div>
  );
}

export default Steps;
