import React, { useState } from "react";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { TextField } from "@mui/material";
import { format } from "date-fns";
import Date from "../Svg/date";
function DatePickerComp({ dateVal, handleDateVal, placeholder }) {
  console.log(dateVal, "dateVal");
  return (
    <div className="bw-flex bw-items-center bw-justify-center">
      <DatePicker
        value={dateVal}
        onChange={(e) => {
          handleDateVal(e);
        }}
        label=""
        format="dd/MM/yyyy"
        slots={{
          openPickerIcon: Date,
        }}
        slotProps={{
          openPickerButton: {
            disableRipple: true,
            sx: {
              "&:hover": {
                backgroundColor: "transparent",
              },
            },
          },
          textField: {
            InputLabelProps: {
              shrink: false,
            },
            placeholder,
            sx: {
              width: "100px",
              fontSize: "12px",
              padding: 0,
              "& .MuiOutlinedInput-root": {
                color: "var(--tw-text-primary)",
                fontSize: "12px",
                padding: "0",
                "& input": {
                  fontFamily: "Lato, sans-serif",
                  padding: "0 0 3px 0px",
                },
                "& fieldset": {
                  border: "none",
                  borderBottom: "1px solid var(--tw-border-primary)",
                  borderRadius: "0",
                },
                "&:hover fieldset": {
                  borderBottom: "1px solid var(--tw-border-primary)",
                },
                "&.Mui-focused fieldset": {
                  borderBottom: "1px solid var(--tw-border-secondary)",
                },
                "& .MuiInputAdornment-root": {
                  margin: "0 0 5px 0",
                },
              },
              "& .MuiOutlinedInput-input::placeholder": {
                fontFamily: "Lato, sans-serif",
                color: "var(--tw-text-secondary)",
                opacity: 1,
              },
              "& .MuiSvgIcon-root": {
                color: "var(--tw-text-primary)",
                fontSize: "12px",
              },
            },
          },
          popper: {
            disablePortal: true,
            sx: {
              "& .MuiPaper-root": {
                border: "1px solid var(--tw-border-primary)",
                borderRadius: "4px",
                boxShadow: "none",
                backgroundColor: "var(--tw-background-container)",
                borderRadius: "10px",
                fontFamily: "Lato, sans-serif",
              },
              "& .MuiPickersDay-root": {
                fontSize: "15px",
                fontWeight: 700,
                color: "var(--tw-text-primary)",
                borderRadius: "4px",
                "&:hover": {
                  backgroundColor: "var(--tw-bg-secondary)",
                },
                "&.MuiPickersDay-today": {
                  border: "1px solid var(--tw-border-primary)",
                  backgroundColor: "transparent",
                },
                "&.Mui-selected": {
                  background: "var(--tw-background-container)",
                  color: "var(--tw-text-primary)",
                  position: "relative",
                  "&::before": {
                    content: '""',
                    position: "absolute",
                    inset: "-1px",
                    padding: "1px",
                    borderRadius: "4px",
                    boxShadow: "0px 0px 4px 0px #00000040",
                    background:
                      "linear-gradient(90deg, #A45EFF 0%, #2CFFE4  100%)",
                    WebkitMask:
                      "linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0)",
                    WebkitMaskComposite: "xor",
                    maskComposite: "exclude",
                  },
                  "&:hover, &:focus": {
                    background: "var(--tw-background-container)",
                  },
                },
                fontFamily: "Lato, sans-serif",
              },
              "& .MuiDayCalendar-weekDayLabel": {
                fontSize: "12px",
                color: "var(--tw-text-primary)",
                fontFamily: "Lato, sans-serif",
              },
              "& .MuiPickersCalendarHeader-label": {
                fontSize: "18px",
                fontWeight: 700,
                color: "var(--tw-text-primary)",
                fontFamily: "Lato, sans-serif",
              },
              "& .MuiPickersYear-yearButton": {
                fontSize: "16px",
                fontWeight: 500,
                color: "var(--tw-text-primary) !important",
                fontFamily: "Lato, sans-serif",
              },
              "& .MuiPickersCalendarHeader-switchViewButton": {
                color: "var(--tw-text-primary)",
              },
              "& .MuiPickersArrowSwitcher-button": {
                color: "var(--tw-text-primary)",
              },
            },
          },
        }}
        dayOfWeekFormatter={(day) => {
          let dayString = day.toString();
          const threeLetterDay = dayString.slice(0, 3);
          return threeLetterDay;
        }}
      />
    </div>
  );
}

export default DatePickerComp;
