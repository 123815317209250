import React from "react";

function inprogress({ fill }) {
  return (
    <svg
      width="61"
      height="61"
      viewBox="0 0 61 61"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M56.5777 31.1653V30.2683C56.5777 16.3363 45.1122 5.04223 30.9687 5.04223C16.8253 5.04223 5.35983 16.3363 5.35983 30.2683V31.1653C5.35983 45.0973 16.8253 56.3914 30.9687 56.3914C45.1122 56.3914 56.5777 45.0973 56.5777 31.1653ZM30.9687 0.716797C14.4002 0.716797 0.96875 13.9475 0.96875 30.2683V31.1653C0.96875 47.4861 14.4002 60.7168 30.9687 60.7168C47.5373 60.7168 60.9687 47.4861 60.9687 31.1653V30.2683C60.9687 13.9475 47.5373 0.716797 30.9687 0.716797Z"
        fill={fill}
      />
      <circle
        cx="30.4678"
        cy="23.4561"
        r="5.49121"
        fill={fill}
        fill-opacity="0.5"
      />
      <circle
        cx="16.4912"
        cy="30.7158"
        r="5.49121"
        fill={fill}
        fill-opacity="0.5"
      />
      <circle
        cx="44.4521"
        cy="30.7158"
        r="5.49121"
        fill={fill}
        fill-opacity="0.5"
      />
    </svg>
  );
}

export default inprogress;
