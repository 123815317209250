import RoundedButton from "../Button/RoundedButton";
import Hamburger from "../Svg/hamburger";
import React, { useState } from "react";
import Settings from "../Modal/Settings";
import useStore from "../../zustand/store";
import SettingsSvg from "../Svg/settings";
export default function Header() {
  const [open, setOpen] = useState(false);
  const fromChain = useStore((state) => state.fromChain);
  function handleClose() {
    setOpen(false);
  }
  return (
    <>
      <div className="bw-flex bw-mb-3  bw-items-center bw-justify-between">
        <p className="bw-text-2xl bw-font-normal bw-text-text-secondary">
          Exchange
        </p>
        <div
          onClick={() => fromChain?.networkType !== "cosmos" && setOpen(true)}
          className={`bw-flex bw-items-center bw-gap-x-2`}
        >
          <RoundedButton classnames={" "}>
            <SettingsSvg fill={"var(--tw-text-secondary)"} />
          </RoundedButton>
        </div>
      </div>
      <Settings open={open} handleClose={handleClose} />
    </>
  );
}
