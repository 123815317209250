import useStore from "../../../zustand/store";
function CustomComp({ inputVal, handleCustomVal, title, inputSideText }) {
  const configuration = useStore((state) => state.configuration);
  return (
    <div className="bw-mt-5  bw-flex bw-items-center bw-justify-between bw-gap-x-3 bw-text-sm bw-font-normal bw-text-text-secondary">
      <h2 className="bw-text-sm bw-font-normal">{title}</h2>
      <div
        style={{
          boxShadow:
            (configuration &&
              configuration.customTheme &&
              configuration.customTheme?.shadow?.boxShadow) ||
            "0px 0px 12.5px 0px #0000001A",
        }}
        className="bw-h-[40px]  bw-w-[70%] md:bw-w-[60%] bw-flex  bw-rounded-md bw-border bw-border-border-primary bw-bg-background-card bw-p-1 bw-text-text-primary"
      >
        <input
          type="number"
          className="bw-bg-transparent bw-text-text-primary bw-text-xl bw-font-medium bw-pl-3 bw-pr-2 bw-w-[80%] bw-h-full bw-text-right"
          value={inputVal}
          onChange={handleCustomVal}
        />
        <div className="bw-w-[20%] bw-h-full  bw-flex bw-justify-center bw-items-center">
          {" "}
          <h2 className="bw-text-sm bw-text-text-secondary  bw-font-normal">
            {inputSideText}
          </h2>
        </div>
      </div>
    </div>
  );
}

export default CustomComp;
