import React from "react";
import DateRange from "../Svg/daterange";
import Inprogress from "../Svg/inprogress";
import Failed from "../Svg/failed";
import Completed from "../Svg/completed";
import EmptyComp from "./EmptyComp";
import useStore from "../../zustand/store";
export default function EmptyState({ value }) {
  const setRoute = useStore((state) => state.setRoute);
  function handleStartTransaction() {
    setRoute("");
  }
  return (
    <div className="bw-w-full bw-h-full bw-flex bw-text-text-secondary bw-justify-center bw-items-center">
      {value == "all" ? (
        <EmptyComp
          heading="No transactions found"
          description="When you start a new transaction, you’ll see its real-time status here"
        ></EmptyComp>
      ) : value == "in-progress" ? (
        <EmptyComp
          heading="No Transactions in Progress"
          description="When you start a new transaction, you’ll see its real-time status here"
        >
          <Inprogress fill={"var(--tw-border-primary)"} />
        </EmptyComp>
      ) : value == "failed" ? (
        <EmptyComp
          heading="No Failed Transactions"
          description="Great news! You haven’t had any failed transactions.
Keep up the good work!"
        >
          <Failed fill={"var(--tw-border-primary)"} />
        </EmptyComp>
      ) : value == "success" ? (
        <EmptyComp
          heading="No Completed Transactions Yet"
          description="Successfully completed transactions will appear here.
Start one now!"
          buttonProps={{
            callback: () => {
              handleStartTransaction();
            },
            buttonText: "Start Transaction",
          }}
        >
          <Completed fill={"var(--tw-border-primary)"} />
        </EmptyComp>
      ) : value == "date" ? (
        <EmptyComp
          heading="No Transactions in Date Range"
          description="Try adjusting range to see more transactions"
        >
          <DateRange fill={"var(--tw-border-primary)"} />
        </EmptyComp>
      ) : (
        <></>
      )}
    </div>
  );
}
