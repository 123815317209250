import React from "react";

function failed({ fill }) {
  return (
    <svg
      width="61"
      height="61"
      viewBox="0 0 61 61"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M56.2808 31.1653V30.2683C56.2808 16.3363 44.8153 5.04223 30.6719 5.04223C16.5285 5.04223 5.06296 16.3363 5.06296 30.2683V31.1653C5.06296 45.0973 16.5285 56.3914 30.6719 56.3914C44.8153 56.3914 56.2808 45.0973 56.2808 31.1653ZM30.6719 0.716797C14.1033 0.716797 0.671875 13.9475 0.671875 30.2683V31.1653C0.671875 47.4861 14.1033 60.7168 30.6719 60.7168C47.2404 60.7168 60.6719 47.4861 60.6719 31.1653V30.2683C60.6719 13.9475 47.2404 0.716797 30.6719 0.716797Z"
        fill={fill}
      />
      <rect
        x="19.0625"
        y="21.5586"
        width="3.83203"
        height="29.0098"
        transform="rotate(-45 19.0625 21.5586)"
        fill={fill}
      />
      <rect
        width="3.83203"
        height="29.0098"
        transform="matrix(-0.707107 -0.707107 -0.707107 0.707107 42.2812 21.5586)"
        fill={fill}
      />
    </svg>
  );
}

export default failed;
