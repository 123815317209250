import React, { useMemo, useState } from "react";
import {
  PieChart,
  Pie,
  Legend,
  ResponsiveContainer,
  Tooltip,
  Cell,
  Sector,
} from "recharts";
import { COLORSCHAIN, COLORSCOIN } from "../../../utils/getColors";
import LegendComp from "./legend";
import AssetsText from "./AssetsText";
import NetWorthComp from "./netWorthComp";
import CustomTooltip from "./CustomTooltip";
import truncate from "../../../utils/truncate";
import { keyBy } from "lodash";
export default function PortfolioChart({ data, selectChain, portfolio }) {
  const [activeIndex, setActiveIndex] = useState();
  function handleSum(val) {
    const initialValue = 0;
    const sumWithInitial = val.reduce(
      (accumulator, currentValue) => accumulator + currentValue.value,
      initialValue
    );
    return sumWithInitial;
  }
  const assets = useMemo(() => {
    let topTen = data?.assets?.slice(0, 10) || [];
    let totalVal = data?.assets;
    let others = data?.assets?.slice(10);
    let othersArray =
      data.assets?.length > 10
        ? [
            {
              name: "Others",
              symbol: "Others",
              value: truncate(handleSum(others), 7) || 0,
              balance: 0,
              percentShare: handleSum(others) / handleSum(totalVal) || 0,
            },
          ]
        : [];
    return [...topTen, ...othersArray];
  }, [data.assets]);

  const chains = useMemo(() => {
    let actualData = data?.chains;
    let topSix = actualData?.slice(0, 4) || [];
    let others = actualData?.slice(4);
    let othersArray =
      actualData?.length > 4
        ? [
            {
              name: "Others",
              value: truncate(handleSum(others), 7) || 0,
              percentShare: handleSum(others) / data.meta?.value || 0,
            },
          ]
        : [];
    return [...topSix, ...othersArray];
  }, [data.chains]);

  const selectedChainObj = useMemo(() => {
    return keyBy(selectChain, "name");
  }, [selectChain]);
  const isAllChains =
    (selectChain[0]?.name === "All Chains" && selectChain.length <= 1) ||
    selectChain.length > 2;

  const renderActiveShape = (props) => {
    const {
      cx,
      cy,
      midAngle,
      innerRadius,
      outerRadius,
      startAngle,
      endAngle,
      fill,
      payload,
      percent,
      value,
    } = props;

    return (
      <g>
        <Sector
          cx={cx}
          cy={cy}
          innerRadius={innerRadius}
          outerRadius={outerRadius + 2}
          startAngle={startAngle}
          endAngle={endAngle}
          fill={fill}
        />
      </g>
    );
  };

  function onPieEnter(data, index) {
    setActiveIndex(index);
  }
  return (
    <>
      <ResponsiveContainer
        className={
          "bw-relative bw-mt-3 bw-mb-0 bw-flex bw-items-center bw-justify-center"
        }
        width={"100%"}
        height={210}
      >
        <p className="bw-absolute bw-w-[55px] bw-text-center bw-text-[10px] bw-text-text-primary bw-font-bold bw-left-[19%] bw-z-0">
          <AssetsText chains={chains} assets={assets} />
        </p>

        <PieChart>
          <Pie
            activeIndex={activeIndex}
            activeShape={renderActiveShape}
            data={chains || []}
            dataKey="value"
            nameKey="name"
            cx="50%"
            cy="50%"
            paddingAngle={2}
            innerRadius={isAllChains ? 35 : 0}
            outerRadius={isAllChains ? 65 : 0}
            onMouseEnter={onPieEnter}
            onMouseLeave={() => setActiveIndex()}
          >
            {chains.map((curr, index) => {
              return (
                <Cell
                  style={{
                    outline: "none",
                    filter: "drop-shadow(-2px 2px 2px #80808080)",
                    opacity:
                      typeof activeIndex !== "undefined" &&
                      activeIndex !== index
                        ? 0.5
                        : 1,
                  }}
                  key={index}
                  stroke="transparent"
                  fill={
                    curr.name === "Others"
                      ? COLORSCHAIN[COLORSCHAIN.length - 1]
                      : COLORSCHAIN[index % COLORSCHAIN.length]
                  }
                />
              );
            })}
          </Pie>
          <Pie
            data={assets || []}
            legendType="none"
            dataKey="value"
            nameKey="name"
            cx="50%"
            cy="50%"
            innerRadius={75}
            outerRadius={100}
            className="bw-relative"
          >
            {assets.map((curr, index) => {
              return (
                <Cell
                  style={{
                    outline: "none",
                    filter: "drop-shadow(2px 2px 2px #80808080)",
                  }}
                  key={index}
                  stroke="transparent"
                  fill={COLORSCOIN[index % COLORSCOIN.length]}
                />
              );
            })}
          </Pie>
          <Legend
            content={
              <LegendComp
                val={data.meta}
                selectChainArray={selectChain}
                selectChain={selectedChainObj}
                activeIndex={activeIndex}
              />
            }
            width={200}
            layout="vertical"
            align="right"
            height={150}
            verticalAlign="middle"
          />
          <Tooltip content={<CustomTooltip />} />
        </PieChart>
      </ResponsiveContainer>
      <NetWorthComp netWorth={data?.meta?.value} portfolio={portfolio} />
    </>
  );
}
